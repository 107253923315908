<template>
	<v-app :style="{ background: $vuetify.theme.themes.light.background }">
		<v-card>
			<template v-if="projectId">
				<v-card-title>プロジェクトの編集</v-card-title>
			</template>
			<template v-else>
				<v-card-title>新規プロジェクト</v-card-title>
			</template>
			<v-btn v-if="projectId" depressed @click="remove" class="remove">削除</v-btn>
			<v-card-text>
				<template v-if="projectId">
					<v-label> ID </v-label>
					<v-text-field v-model="project.id" outlined disabled></v-text-field>
				</template>

				<v-label>名称</v-label>
				<v-text-field :rules="[rules.required, rules.max]" counter="20" outlined v-model="project.name"> </v-text-field>

				<v-label>説明</v-label>
				<v-textarea :rules="[rules.required, rules.max2]" counter="100" outlined v-model="project.description"> </v-textarea>

				<v-label>地点一覧</v-label>
				<v-select
					v-model="project.places"
					:items="places"
					multiple
					chips
					outlined
					persistent-hint
				></v-select>
				<template>
					<v-label>招待コード (変更も可能)</v-label>
					<v-text-field :rules="[rules.required, rules.max]" counter="20" outlined v-model="project.code"> </v-text-field>
				</template>
			</v-card-text>
			<template v-if="projectId">
				<v-card-actions>
					<v-btn depressed @click="save" class="save">保存</v-btn>
				</v-card-actions>
			</template>
			<template v-else>
				<v-card-actions>
					<v-btn depressed @click="save" class="save">プロジェクトを追加</v-btn>
				</v-card-actions>
			</template>
		</v-card>
		<v-dialog v-model="dialogDelete" max-width="500px">
			<v-card>
				<v-card-title class="headline"
					>本当にこのプロジェクトを削除しますか？</v-card-title
				>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDelete"
						>キャンセル</v-btn
					>
					<v-btn color="blue darken-1" text @click="deleteConfirm">OK</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
	</v-app>
</template>

<script>
import axios from "axios";

export default {
	components: {},
	data: () => ({
		projectId: "",
		project: {},
		places: [],
		dialogDelete: false,
		rules: {
			required: v => !!v || '必須項目',
			max: v => v.length <= 20 || "20文字以内",
			max2: v => v.length <= 12 || "12文字以内",
		},
	}),
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		this.initialize();
	},
	mounted() {
		this.projectId = this.$route.params.id;
	},
	methods: {
		initialize() {
			this.showLoading();
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-update-project?id=" +
						this.$route.params.id
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						this.project = res.data.project;
						this.places = res.data.places;
						console.log("Success: " + JSON.stringify(res.data));
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		save() {
			if (!this.project.name || this.project.name.length > 20) {
				alert("プロジェクト名称は20文字以内で指定してください");
				return;
			}
			if (this.project.description && this.project.description.length > 100) {
				alert("プロジェクト説明は100文字以内で指定してください");
				return;
			}
			if (!this.project.code || this.project.code.length > 20) {
				alert("プロジェクト招待コードは20文字以内で指定してください");
				return;
			}
			this.showLoading();
			const params = {
				name: this.project.name,
				description: this.project.description,
				code: this.project.code,
				places: this.project.places,
			};
			if (this.projectId) params.id = this.projectId;
			axios
				.post(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-update-project",
					params
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.$router.push({ name: "AdminProject" }).catch(() => {});
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
					alert("名称、説明、招待コードは必須項目です");
				});
		},
		closeDelete() {
			this.dialogDelete = false;
		},
		deleteConfirm() {
			this.showLoading();
			const params = {
				id: this.projectId,
				delete: "true",
			};
			axios
				.post(
					"https://" +
						this.config.defaultOriginString +
						"/api/admin-update-project",
					params
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.$router.push({ name: "AdminProject" }).catch(() => {});
					} else {
						console.log("Fail: " + JSON.stringify(res));
						alert("不明なエラー");
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
					alert("不明なエラー");
				});
		},
		remove() {
			this.dialogDelete = true;
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
	},
};
</script>

<style>
</style>
